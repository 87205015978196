import store from "@/state/store";

// const currentEntity = store.getters["auth/currentEntity"];
const currentUser = store.getters["auth/currentUser"];

// const id = currentEntity.id;
const user = currentUser.id;

export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "Dashboard",
    icon: "ri-dashboard-line",
    link: "/dashboard",
  },
  {
    id: 3,
    label: " My Entities",
    icon: "fas fa-users",
    link: "/user/entities",
  },
  {
    id: 4,
    label: " Courses",
    icon: "ri-book-open-fill",
    link: `/user/${user}/courses`,
  },
  {
    id: 5,
    label: "My Courses",
    icon: "fa fa-user-circle",
    link: `/user/${user}/enroll_courses`,
  },
  {
    id: 6,
    label: "My Certificates",
    icon: "fa fa-certificate",
    link: `/user/${user}/enroll_courses/certificates`,
  },
  {
    id: 7,
    label: " Certified Users",
    icon: "fas fa-graduation-cap",
    link: `/user/${user}/certificates`,
  },
  {
    id: 18,
    isLayout: true,
  },
];
